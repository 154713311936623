import {
  Container,
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Text,
  Heading,
  VStack,
} from '@chakra-ui/react';

function Login(props) {
  return (
    <Container mw="continer.xl" mb="40px" py="40px" bgColor="gray.100" boxShadow="0px 4px rgba(0,0,0,0.2)">
      <Heading color="black" pb="buffer.sm">Log in</Heading>
      <Text fontSize="lg" pl="10px" pb="buffer.sm">Log in to gain exclusive access to our unique approach
      to VPPA purchasing.</Text>
    </Container>
  )
}

export default Login;

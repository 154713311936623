import {
  Container,
  Box,
  Button,
  Grid,
  GridItem,
  Flex,
  Image,
  Heading,
  HStack,
  Text
} from '@chakra-ui/react';
// Custom components
import HeadingBig from './text/HeadingBig'
import HeadingSmall from './text/HeadingSmall'

// 3. map to grid items
function SignUp({changeDisplay, ...props}) {
  return (
    <Container maxW="container.lg">
      <HeadingBig borderColor="white" color="white" mb="buffer.md">Member benefits</HeadingBig>
      <Container>
        <Box color="white" pb="buffer.md">
          <ul>
            <li>Receive detailed market education</li>
            <li>Access to actionable VPPA opportunities</li>
            <li>Analytics to ensure you sign the best deal for your company</li>
          </ul>
        </Box>
        <Button colorScheme="gray" color="black" px={10} py={6} fontWeight="100" fontSize="lg"
          onClick={() => {changeDisplay("none", "block", "none")}}>
          Become a member
        </Button>
      </Container>
    </Container>
  )
}

export default SignUp;

// import 'dotenv/config';
import {
  Container,
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Text,
  Heading,
  VStack,
} from '@chakra-ui/react';
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
// CSS
import '../../assets/style.css'

/*
    Name
    Email
    Company
    Have you transacted in a VPPA previously? [Yes or No]
    Volume of energy AND/OR Tonnes of carbon offset [Number in MWh and Tonnes]
    Do you have operations in alberta? [yes or no]
        -> if yes: How much energy do you consume in Alberta per year [MWh]
*/
//  {formData.opsInAlberta === "Yes" ? <label>Test</label> : null}

function Form() {
    const form = useRef();

    const initialFormData = Object.freeze({
        from_name: "",
        from_email: "",
        company_name: "",
        transacted_vppa: "",
        volumeOfEnergy: "",
        tonnesCarbonOffset: "",
        opsInAlberta: "",
        energyConsumptionAlberta: "",
        message: ""
    });

    const [formData, updateFormData] = React.useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.opsInAlberta === "No") formData.energyConsumptionAlberta = "0";

        // get form data and send email 
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    function checkOpsInAlberta() {
        return (
            <div>
                <label for="">If yes, how much energy do you consume per year in Alberta:
                    <input
                        type="text"
                        name="energyConsumptionAlberta"   /* Use the same variable from the email template in EmailJS */
                        onChange={handleChange}
                    />
                </label>
            </div>
        );
    };

    return (

            <form className="signup-form" ref={form} onSubmit={handleSubmit}>
              <VStack justify="left" align="left">
                <label>
                  <div>Full Name:</div>
                    <input
                        type="text"
                        name="from_name"   /* Use the same variable from the email template in EmailJS */
                        onChange={handleChange}
                    />
                </label>
                <label>
                  <div>Email:</div>
                    <input
                        type="text"
                        name="from_email"   /* Use the same variable from the email template in EmailJS */
                        onChange={handleChange}
                    />
                </label>
                <label>
                  <div>Company Name:</div>
                    <input
                        type="text"
                        name="company_name"   /* Use the same variable from the email template in EmailJS */
                        onChange={handleChange}
                    />
                </label>
                <label>
                  <div>Have you transacted in a VPPA previously?</div>
                    <div class="radio-cluster">
                      <div>Yes</div>
                      <input
                          type="radio"
                          name="transacted_vppa"   /* Use the same variable from the email template in EmailJS */
                          value="Yes"
                          onChange={handleChange}
                      />
                    </div>
                    <div class="radio-cluster">
                      <div>No</div>
                      <input
                          type="radio"
                          name="transacted_vppa"   /* Use the same variable from the email template in EmailJS */
                          value="No"
                          onChange={handleChange}
                      />
                    </div>
                </label>
                <label>
                  <div>Volume of Energy in MWh:</div>
                    <input
                        type="text"
                        name="volumeOfEnergy"   /* Use the same variable from the email template in EmailJS */
                        onChange={handleChange}
                    />
                </label>
                <label>
                  <div>Carbon Offset in Tonnes:</div>
                    <input
                        type="text"
                        name="tonnesCarbonOffset"   /* Use the same variable from the email template in EmailJS */
                        onChange={handleChange}
                    />
                </label>
                <label>
                <div>Do you have current operations in Alberta?</div>
                  <div class="radio-cluster">
                    <div>Yes</div>
                    <input
                        type="radio"
                        name="opsInAlberta"   /* Use the same variable from the email template in EmailJS */
                        value="Yes"
                        onChange={handleChange}
                    /> 
                  </div>
                  <div class="radio-cluster">
                    <div>No</div>
                    <input
                        type="radio"
                        name="opsInAlberta"   /* Use the same variable from the email template in EmailJS */
                        value="No"
                        onChange={handleChange}
                    />
                  </div>
                </label>

                {formData.opsInAlberta === "Yes" ? checkOpsInAlberta() : null}

                <label>Comments:
                    <textarea
                        name="message"     /* Use the same variable from the email template in EmailJS */
                        onChange={handleChange}
                    />
                </label>
                </VStack>

                <input type="submit" value="Submit" />
            </form>
    )
}

export default Form;

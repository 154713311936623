import { Box, Center, Heading } from '@chakra-ui/react';

function NumberCircle(props) {
  return (
    <Center
      w="6px"
      h="6px"
      p={10}
      bgColor="white"
      color="black"
      borderRadius="circle"
      fontSize="2xl"
      fontWeight="bold"
      {...props}
    />
  )
}

export default NumberCircle;

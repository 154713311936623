import { Box } from '@chakra-ui/react'

//https://chakra-ui.com/docs/styled-system/theming/component-style

// make sure padding uses breakpoints
function Section(props) {
  return (
    <Box as="section" w="full" py="100px" {...props} />
  )
}

export default Section;

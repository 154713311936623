import { Heading } from '@chakra-ui/react';

function HeadingBig(props) {
  return (
    <Heading
      fontWeight="900"
      textShadow="0px 2px rgba(0,0,0,0.2)"
      pb="buffer.md"
      mb="10px"
      borderBottom="1px solid black"
      textAlign={{base: "center", md: "left"}}
      {...props}
    />
  )
}

export default HeadingBig;

import {
  Container,
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Text,
  Heading,
  VStack,
} from '@chakra-ui/react';
import Form from './Form'


function BecomeAMember(props) {
  return (
    <Container mw="continer.xl" mb="40px" py="40px" bgColor="gray.100" boxShadow="0px 4px rgba(0,0,0,0.2)">
      <Heading color="black" pb="buffer.sm">Contact Us</Heading>
      <Text fontSize="lg" pl="10px" pb="buffer.sm">Fill in the fields below to be added to our list. We
      will contact you in 1-2 business days with further information.</Text>
      <Form />
    </Container>
  )
}

export default BecomeAMember;

import {
  Container,
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Heading,
  HStack,
  Text
} from '@chakra-ui/react';
// Custom components
import HeadingBig from './text/HeadingBig'
import HeadingSmall from './text/HeadingSmall'
import NumberCircle from './text/NumberCircle.js'
// 1. import all Logos
import amazon from '../img/logos/amazon.png'
import bimbo from '../img/logos/bimbo.png'
import bullfrog from '../img/logos/bullfrog.png'
import cenovus from '../img/logos/cenovus.png'
import directEnergy from '../img/logos/directEnergy.png'
import dow from '../img/logos/dow.png'
import enbridge from '../img/logos/enbridge.png'
import keyara from '../img/logos/keyara.png'
import labatt from '../img/logos/labatt.png'
import microsoft from '../img/logos/microsoft.png'
import pembina from '../img/logos/pembina.png'
import rbc from '../img/logos/rbc.png'
import scotiabank from '../img/logos/scotiabank.png'
import shell from '../img/logos/shell.png'
import tc from '../img/logos/tc2.png'
import telus from '../img/logos/telus.png'

// 2. create logo array
const logos = [amazon, bimbo, bullfrog, cenovus, directEnergy, dow,
  enbridge, keyara, labatt, microsoft, pembina, rbc, scotiabank,
  shell, tc, telus]

  /*

  <Grid templateColumns={{base: "1fr 1fr", md: "1fr 1fr 1fr", lg: "1fr 1fr 1fr 1fr"}}>
    {logos.map((logo)=>
      <GridItem p={{base: "20px", md: "10px"}}>
        <Image src={logo} maxW="200px"/>
      </GridItem>
    )}
  </Grid>

  */

// 3. map to grid items
function Companies(props) {
  return (
    <Container maxW="container.xl">
      <Flex
        flexDirection={{base: "column", md: "row-reverse"}}
        justify="space-between"
        align="space-evenly"
      >
        <Box>
          <Heading pb="buffer.sm">Join industry leaders</Heading>
          <Text pb="buffer.md">These are just some of the investment-grade companies that 
          have signed renewable energy VPPAs in Alberta. </Text>
        </Box>
        <Box pr="100px"/>
        <Flex
          wrap="wrap"
          justify="space-evenly"
        >
          {logos.map((logo)=>
            <Box p={{base: "5px", md: "10px"}} key={logo}>
              <Image src={logo} maxW="200px"/>
            </Box>
          )}
        </Flex>
      </Flex>
    </Container>
  )
}

export default Companies;

// Chakra Provider:
import {
  Container,
  Box,
  Button,
  Text,
  Heading,
  VStack,
} from '@chakra-ui/react';

function Hero({changeDisplay, ...props}) {
  return (
    <VStack h="100%" justify="space-evenly" {...props}>
      <Box />
      <Container maxW="container.xl">
        <VStack color="text.body">
          <Heading
            fontStyle="none"
            fontWeight='hairline'
            pb="buffer.sm" color="#fff"
          >
            Net Zero Now
          </Heading>
          <Text pb="buffer.sm" align="center" color="#fff" fontSize="xl">Virtual Power Purchase Agreements (VPPAs) made simple.</Text>
          <Button 
            colorScheme="red" px={10} py={6} fontWeight="100" fontSize="lg"
            onClick={() => {changeDisplay("none", "block", "none")}}
          >
            Become a member
          </Button>
        </VStack>
      </Container>
      <Box />
    </VStack>
  )
}

export default Hero;

import React, { useState }  from 'react';
// Fonts:
import '@fontsource/anek-gujarati'
import '@fontsource/montserrat'
// Chakra Provider:
import {
  ChakraProvider,
  Box,
  Text,
  Heading,
  VStack,
  Image
} from '@chakra-ui/react';
// Custom components:
import Section from './components/layout/Section'
import Full from './components/layout/Full'
import Header from './components/Header'
import Hero from './components/Hero'
import CallToAction from './components/CallToAction'
import HowWeCanHelp from './components/HowWeCanHelp'
import About from './components/About'
import WhyJoin from './components/WhyJoin'
import Companies from './components/Companies'
import BounceIn from './components/animated/BounceIn'
import SignUp from './components/SignUp'
import BecomeAMember from './components/account/BecomeAMember'
import Login from './components/account/Login'
// Images:
import drone3 from './img/drone-3.png'
// Custom theme:
import customTheme from './theme'

function App() {

  const [display, setDisplay] = useState(
      {
        home: "block",
        becomeAMember: "none",
        login: "none"
      }
    );
    const handleDisplayChange = (newHome, newBecomeAMember, newLogin) => {
      console.log("disp change")
      setDisplay({
        home: newHome,
        becomeAMember: newBecomeAMember,
        login: newLogin
      })
    }

    const [test, setTest] = useState("test");
    const handleTestChange = (event) => {
      setTest("monkey");
      console.log("monkey")
    }

    console.log(test);



    console.log(display.home);

  return (
    <ChakraProvider theme={customTheme}>
      <Full id="become-a-member" pt="100px" display={display.becomeAMember}>
          <BecomeAMember />
      </Full>
      <Full id="login" pt="100px" display={display.login}>
        <Login />
      </Full>
      <Header changeDisplay={handleDisplayChange} top="0px"/>
      <Box id="home" display={display.home}>
        <Section
          id="landing-page"
          h="100vh"
          bgImage={"linear-gradient(0deg,rgba(0,0,10,.5),rgba(0,0,10,.3)), url(" + drone3 + ")"}
          bgSize="cover"
          bgPosition="center"
          bgAttachment="fixed"
        >
          <Hero changeDisplay={handleDisplayChange} />
        </Section>
        <Section
          id="how"
          mt="-210px"
          bgGradient="linear(to-br, none, none, white)"
        >
          <Box h="110px" bg="linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 255, 255, 1))" />
          <About />
        </Section>
        <Section
          id="how-we-can-help"
          bgColor="red.500"
          bgGradient="red.500"
        >
          <WhyJoin />
        </Section>
        <Section id="companies">
          <Companies />
        </Section>
        <Section
          id="contact"
          bgColor="red.500"
        >
          <SignUp changeDisplay={handleDisplayChange} />
        </Section>
      </Box>
    </ChakraProvider>
  );
}

export default App;

// Chakra Provider:
import {
  Container,
  Box,
  Button,
  Center,
  Image,
  Text,
  Icon,
  Flex,
  Heading,
  VStack,
  HStack,
  Grid,
  GridItem
} from '@chakra-ui/react';
//import LineGraph from './data/LineGraph.js'
// Custom components:
import HeadingBig from './text/HeadingBig'
import HeadingSmall from './text/HeadingSmall'
import BounceIn from './animated/BounceIn'
// Icons:
import lineGraph from '../img/data/line-graph.png'

function About(props) {
  return (
    <Container maxW="container.xl" pt="80px">
      <Grid
        templateColumns={{base: "1fr", md: "1fr 1fr"}}
        gap="buffer.lg"
      >
        <GridItem pb="buffer.md">
          <Box py="50px">
            <HeadingBig mb="buffer.sm">
            Net Zero Now improves the value of VPPA transactions
            </HeadingBig>
            <Text color="#444" fontSize="xl" pb="buffer.sm">
            The process of purchasing renewable energy and Carbon Offsets from large-scale projects is slow, cumbersome, and expensive. 
            Our mission is to make the process more simple, accessible, and cost effective for buyers. 
            </Text>
            <Text color="#444" fontSize="xl" pb="buffer.sm">
            We do this by:
            </Text>
          </Box>
          <HStack alignItems="baseline" align="center" pb="buffer.sm">
          <Box fontSize="10px" color="red.500" pl={{base: "0px", md: "20px"}}>{'\u2B24'}</Box>
            <Text>
            <strong>Aggregating</strong> your demand with other buyers. By doing this, we can achieve economies
            of sale pricing, giving your business access to the best prices in the market.
            </Text>
          </HStack>
          <HStack alignItems="baseline" align="center" pb="buffer.sm">
          <Box fontSize="10px" color="red.500" pl={{base: "0px", md: "20px"}}>{'\u2B24'}</Box>
            <Text>
            <strong>Accelerating</strong> your timeline to transact. We don't offer education and hourly consulting. 
            We provide actionable opportunities to transact on a continuous basis. 
            </Text>
          </HStack>
          <HStack alignItems="baseline" align="center" pb="buffer.sm">
            <Box fontSize="10px" color="red.500" pl={{base: "0px", md: "20px"}}>{'\u2B24'}</Box>
            <Text>
            <strong>Reducing</strong> soft costs related to market education, RFP tendering, executive buy-in, and legal review.
            </Text>
          </HStack>
        </GridItem>
        <GridItem>
            <Flex h="100%" justify="center" align="center" pr="0px" direction="column" wrap>
              <Image src={lineGraph} />
            </Flex>
        </GridItem>
      </Grid>
    </Container>
  )
}

export default About;

import {
  Container,
  Box,
  Heading,
  HStack,
  Text
} from '@chakra-ui/react';
// Custom components
import HeadingBig from './text/HeadingBig'
import HeadingSmall from './text/HeadingSmall'
import NumberCircle from './text/NumberCircle.js'

function HowWeCanHelp(props) {
  return (
    <Container maxW="container.xl">
      <HeadingBig color="#fff">How we improve the value of your VPPN transactions.</HeadingBig>
      <Text color="#fff" fontSize="xl">Net Zero Now improves the value of your transaction in several ways.</Text>
      <Container maxW="container.l">
        <HStack py={{base: "40px", md: "20px"}} >
          <NumberCircle color="red.300" textShadow="1px 2px rgba(0,0,0,0.2)">1</NumberCircle>
          <Container>
            <Text color="#fff">Aggregating your demand with other buyers
            to achieve economies of scale pricing.</Text>
          </Container>
        </HStack>
        <HStack py="20px" flexDirection="row-reverse">
          <NumberCircle color="red.300">2</NumberCircle>
          <Container>
            <Text color="#fff">Accelerating  your timeline to transact
            by providing you with actionable transactions within 1 month.
            </Text>
          </Container>
        </HStack>
        <HStack py="20px">
          <NumberCircle color="red.300">3</NumberCircle>
          <Container>
            <Text color="#fff">Reducing soft costs related to market eduction,
            RFP tendering, and legal review.
            </Text>
          </Container>
        </HStack>
      </Container>
    </Container>
  )
}

export default HowWeCanHelp;

import { Heading } from '@chakra-ui/react';

function HeadingSmall(props) {
  return (
    <Heading
      as="h4"
      pb="buffer.sm"
      fontSize="lg"
      fontWeight="900"
      {...props}
    />
  )
}

export default HeadingSmall;

import { extendTheme } from '@chakra-ui/react'

// Overriding the defaults: https://chakra-ui.com/docs/styled-system/theming/customize-theme

// Extend the theme to include custom colors, fonts, etc
const customTheme = extendTheme({
  // Overriding the global styles:
  styles: {
    global: {
      body: {
        fontWeight: 100,
        fontSize: 'xl'
      },
      h1: {
        fontSize: '6xl'
      },
      h2: {
        fontSize: '3xl'
      },
      h3: {
        fontSize: '2xl'
      },
      h4: {

      }
    }
  },
  // Custom colours:
  colors: {
    none: "rgba(0,0,0,0)",
    text: {
      body: '#fff'
    },
    red: {
      400: '#ff837f',
      500: '#ff514c',
      600: '#ff211a',
      700: '#e60800',
      800: '#b40300',
      900: '#810000'
    },
    gray: {
      100: '#faf9f9'
    }
  },
  space: {
    buffer: {
      sm: '1rem',
      md: '2rem',
      lg: '4rem',
      xl: '8rem'
    }
  },
  fonts: {
    body: 'anek gujarati, sans-serif',
    heading: 'sans-serif',
    mono: 'Menlo, monospace',
  },
  spacing: {
    height: {
      full: '100vh'
    }
  },
  radii: {
    circle: '50%'
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: '100',
        color: 'red.500'
      }
    },
    Text: {
      baseStyle: {
        color: '#222'
      }
    }
  }
})

export default customTheme

import {
  Container,
  Center,
  Box,
  Heading,
  HStack,
  Text
} from '@chakra-ui/react';
// Custom components
import HeadingBig from './text/HeadingBig'
import HeadingSmall from './text/HeadingSmall'
import NumberCircle from './text/NumberCircle.js'
// Images
import drone3 from '../img/drone-3.png'


const bulletPoints = [
  {
    number: 1,
    name: "Hedge",
    text: "Profitably hedge against exposure to future electricity and the rising Canadian federal carbon price"
  },
  {
    number: 2,
    name: "Create",
    text: "Create meaningful reductions in corporate carbon emissions"
  },
  {
    number: 3,
    name: "Capture",
    text: "Capture the early-mover advantage in Alberta afforded by the Electricity Grid Displacement Factor for renewable generators"
  },
  {
    number: 4,
    name: "Transact",
    text: "Transact with the highest-value projects in Alberta "
  }
]

function WhyJoin(props) {
  return (
    <Container maxW="container.xl">
      <HeadingBig borderColor="white" color="white">Why VPPAs? Why Now?</HeadingBig>
      <Text color="#fff" fontSize="xl" pb="buffer.md">
        VPPAs are an increasingly popular ESG tool for companies looking to make a meaningful impact on
        their carbon footprint and achieve their Net Zero goals.</Text>
        <Text color="#fff" fontSize="xl" pb="buffer.md">Many of the world's leading companies
        are transacting directly with projects in Alberta to:</Text>
      <Container
        maxW="container.lg"
        p={{base: "20px", md: "40px"}}
        bgColor="white"
        //bgImage={"linear-gradient(0deg,rgba(10,10,50,.2),rgba(50,10,50,.3)), url(" + drone3 + ")"}
        bgSize="cover"
        bgPosition="center"
        bgAttachment="fixed"
        >
        { bulletPoints.map((bullet) =>
          <HStack pb="10px"
            flexDirection="row"
            spacing="10px"
            key={bullet.name}
          >
            <NumberCircle
              color="red.300"
              >{bullet.number}</NumberCircle>
            <Center maxW="container.sm">
              <Text color="black">{bullet.text}</Text>
            </Center>
          </HStack>
        )}
      </Container>
    </Container>
  )
}

export default WhyJoin;

import { Box, Button, Container, Center, Heading, HStack, Grid, GridItem, Image, Text } from '@chakra-ui/react'
import logo from '../img/nzn-logo.png'
import logoText from '../img/nzn-logo-text.png'

// make sure padding uses breakpoints
function Header({changeDisplay, ...props}) {
  return (
      <Grid
        position="absolute"
        w="full"
        h="100px"
        p="10px"
        alignItems="center"
        columns={3}
        templateColumns={{base: "1fr 1fr 1fr 1fr", md: "1fr 50% 1fr 1fr", lg: "1fr 60% 1fr 1fr"}}
        transition='all 0.4s cubic-bezier(.08,.52,.52,1)'
        bgColor="#fff"
        {...props}
      >
        <GridItem >
          <Box as="button" w={48} onClick={()=>{changeDisplay("block", "none", "none")}}>
            <Image
              h={20}
              src={logo}
              display={{base: "block", md: "none"}}
            />
            <Image
              h={20}
              src={logoText}
              display={{base: "none", md: "block"}}
            />
          </Box>
        </GridItem>
        <GridItem />
        <GridItem>
          <Center>
            <Text as="a" display="none">About</Text>
          </Center>
        </GridItem>
        <GridItem>
          <Center>
            <Button 
              p={6} colorScheme="red"
              onClick={()=>{changeDisplay("none", "none", "block")}}>Log in</Button>
          </Center>
        </GridItem>
      </Grid>
  )
}

export default Header;
